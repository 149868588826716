import React, { FunctionComponent } from "react"
import ServerSVG from "../assets/serverSVG"
import ThursdayProductLogo from "../assets/thursdayProductLogo"
import "./ErrorPage.css"

const ErrorPage = ({}) => {
  return (
    <div className="error-container">
      <div className="error-logo-container">
        <ThursdayProductLogo />
      </div>

      <div className="error-content">
        <ServerSVG />
        <div className="error-code">ERROR 404</div>
        <div className="error-header">Is it friday today?</div>
        <div className="error-description">
          We’re sorry, this wasn’t supposed to happen.
          <br />
          Try going{" "}
          <a href="javascript:history.back()" className="error-link">
            back
          </a>{" "}
          or{" "}
          <a href="/" className="error-link">
            home
          </a>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
