import React, { FunctionComponent } from "react"

interface ServerSVGProps {}

const ServerSVG: FunctionComponent<ServerSVGProps> = ({}) => {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 33.3333H13.8333C12.0652 33.3333 10.3695 32.631 9.11929 31.3807C7.86905 30.1305 7.16667 28.4348 7.16667 26.6667V13.3333C7.16667 11.5652 7.86905 9.86954 9.11929 8.61929C10.3695 7.36905 12.0652 6.66667 13.8333 6.66667H67.1667C68.9348 6.66667 70.6305 7.36905 71.8807 8.61929C73.131 9.86954 73.8333 11.5652 73.8333 13.3333V26.6667C73.8333 28.4348 73.131 30.1305 71.8807 31.3807C70.6305 32.631 68.9348 33.3333 67.1667 33.3333H60.5"
        fill="#DFE6EB"
      />
      <path
        d="M20.5 33.3333H13.8333C12.0652 33.3333 10.3695 32.631 9.11929 31.3807C7.86905 30.1305 7.16667 28.4348 7.16667 26.6667V13.3333C7.16667 11.5652 7.86905 9.86954 9.11929 8.61929C10.3695 7.36905 12.0652 6.66667 13.8333 6.66667H67.1667C68.9348 6.66667 70.6305 7.36905 71.8807 8.61929C73.131 9.86954 73.8333 11.5652 73.8333 13.3333V26.6667C73.8333 28.4348 73.131 30.1305 71.8807 31.3807C70.6305 32.631 68.9348 33.3333 67.1667 33.3333H60.5"
        stroke="#B2BCC6"
        strokeWidth="6.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 46.6667H13.8333C12.0652 46.6667 10.3695 47.3691 9.11929 48.6193C7.86905 49.8695 7.16667 51.5652 7.16667 53.3333V66.6667C7.16667 68.4348 7.86905 70.1305 9.11929 71.3807C10.3695 72.631 12.0652 73.3333 13.8333 73.3333H67.1667C68.9348 73.3333 70.6305 72.631 71.8807 71.3807C73.131 70.1305 73.8333 68.4348 73.8333 66.6667V53.3333C73.8333 51.5652 73.131 49.8695 71.8807 48.6193C70.6305 47.3691 68.9348 46.6667 67.1667 46.6667H60.5"
        fill="#DFE6EB"
      />
      <path
        d="M20.5 46.6667H13.8333C12.0652 46.6667 10.3695 47.3691 9.11929 48.6193C7.86905 49.8695 7.16667 51.5652 7.16667 53.3333V66.6667C7.16667 68.4348 7.86905 70.1305 9.11929 71.3807C10.3695 72.631 12.0652 73.3333 13.8333 73.3333H67.1667C68.9348 73.3333 70.6305 72.631 71.8807 71.3807C73.131 70.1305 73.8333 68.4348 73.8333 66.6667V53.3333C73.8333 51.5652 73.131 49.8695 71.8807 48.6193C70.6305 47.3691 68.9348 46.6667 67.1667 46.6667H60.5"
        stroke="#B2BCC6"
        strokeWidth="6.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 20H20.5333"
        stroke="#EC4747"
        strokeWidth="6.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 60H20.5333"
        stroke="#FFC200"
        strokeWidth="6.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.8333 20L30.5 40H50.5L37.1667 60"
        stroke="#2F373D"
        strokeWidth="6.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ServerSVG
