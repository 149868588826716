import * as React from "react"
import ErrorPage from "../components/ErrorPage"
import Seo from "../components/seo"
const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <ErrorPage />
  </>
)

export default NotFoundPage
