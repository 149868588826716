import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ canonical, description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: "https://thursday.social/",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: `@ThursdayByFolly`,
        },
        {
          name: "twitter:image",
          content:
            "https://static.thursday.social/thursday2.0_OGImage.png",
        },
        {
          name: "twitter:image:alt",
          content: "Thursday.social",
        },
        {
          name: "og:image",
          content:
            "https://static.thursday.social/thursday2.0_OGImage.png",
        },
        {
          name: "og:image:width",
          content: "1140"
        },
        { 
          name:"og:image:type",
          content:"image/png" 
        },
        {
          name: "og:image:height",
          content: "597"
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      link={canonical ? [{ rel: "canonical", href: canonical }] : []}
    >
      <script src="//in.fw-cdn.com/30347668/196544.js" chat="false"></script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string,
}

export default Seo
